$(document).ready(function () {
    
    var GlideOptions = {
        type: 'carousel',
        startAt: 0,
        perView: 1,
        gap: 0,
        autoplay: 3900,
    }
  

    if( $('.hero_glide').length ) {
        var hero_glide = new Glide('.hero_glide', GlideOptions).mount();
    } 
    if( $('.project_glide').length ) {
        console.log('project glide');
        var project_glide = new Glide('.project_glide', GlideOptions).mount();
    } 
  

    // generic carousel navigation
    $('.js-glide-prev').click(function(e) {
        e.preventDefault();
        var glider = $(this).data("target-slider");
        eval(glider).go('<');
    });

    $('.js-glide-next').click(function(e) {
        e.preventDefault();
        var glider = $(this).data("target-slider");
        eval(glider).go('>');
    });  

    $('.js-glide-goto').click(function(e) {
        e.preventDefault();
        var glider = $(this).data("target-slider");
        var slideNum = $(this).data("slider-index")-1;
        eval(glider).go('='+slideNum);
    });  
    
});


$(function() {
   var form = $('#contact');

   $(form).submit(function(event) {
       event.preventDefault();

       var formData = $(form).serialize();

       $.ajax({
           type: 'POST',
           url: $(form).attr('action'),
           data: formData
       }).done(function(response) {
           if (response.success) {
               $(form).find('.succes-message').show();
               $(form).find('.fail-message').hide();
               form.trigger("reset");
           }
           else {
               $(form).find('.succes-message').hide();
               $(form).find('.fail-message').show();
           }
       }).fail(function(response) {
            $(form).find('.succes-message').hide();
            $(form).find('.fail-message').show();
            $(form).find('.fail-message  .message').html('');

            $.each(response.responseJSON.errors, function(k, values) {
               $(form).find('.fail-message .message').append('<br />'+values);
           });
       });
   });
});